'use client';
import getLocal from '@/app/utils/i18n';
import { subscribeFn } from '@/service/common';
import gtm from '@/utils/gtm';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import EditorImg from 'lowcode/components/EditorImg';
import { FC, useState } from 'react';
import { DEFAULT_DATA, getLocalText } from '../config';
import styles from './index.module.scss';

const Content: FC<{ id: string; attr: any; data: any }> = ({ id, attr, data = DEFAULT_DATA }) => {
    const { title, img, bgColor, titleColor, termsUrl, policyUrl, emarsysType } = data;
    const { locale } = getLocal();
    const { subscribe, placeholder, success, error, check, termsAndPolicy } = getLocalText(locale, termsUrl, policyUrl);
    const [email, setEmail] = useState('');
    const [checked, setChecked] = useState(false);
    const [inputMsg, setInputMsg] = useState('');
    const [tipType, setTipType] = useState(0); // 0 init 1 success 2 error

    const validEmail = (_email: string) => {
        if (!/(.+)@(.+){2,}\.(.+){2,}/.test(_email)) {
            setInputMsg(error);
            setTipType(2);
            return false;
        }
        if (!checked) {
            setInputMsg(check);
            setTipType(2);
            return false;
        }
        setInputMsg('');
        return true;
    };

    const handleEmail = (e: any) => {
        const emailVal = e.target.value.trim();
        setEmail(emailVal);
        // validEmail(emailVal);
    };

    const handleCheck = () => {
        setChecked(!checked);
        if (!checked && inputMsg) {
            setInputMsg('');
        }
        if (checked && !inputMsg) {
            setInputMsg(check);
        }
    };

    const submitSubscribe = async (event: any) => {
        event.preventDefault();
        if (!validEmail(email)) {
            return;
        }
        const res = await subscribeFn(email, emarsysType || 'WEB_FOOTER');
        if (res?.code === '0') {
            setTipType(1);
            setInputMsg(success);
            gtm.push({
                event: 'uaEvent',
                event_name: 'activity_submit',
                button_name: subscribe,
                status: 'success',
                tag: 'Agree',
            });
        } else {
            setTipType(2);
            setInputMsg(res?.message);
            gtm.push({
                event: 'uaEvent',
                event_name: 'activity_submit',
                button_name: subscribe,
                status: 'fail',
                tag: 'Agree',
            });
        }
        setEmail('');
    };

    return (
        <section
            className={`${styles.content} temp-subscribeEmail temp-hide temp-${id} GtmModuleData-section`}
            id={id}
            data-section-id="editor-subscribeEmail"
            data-section-id2={id}
            data-section-classname=".info-box .title"
        >
            <DefaultStyle id={id} attr={attr} />
            <div
                className="subscribe-email-box"
                style={{
                    backgroundColor: bgColor,
                }}
            >
                <div className="img-box h5">
                    <EditorImg pc={img} alt={title} />
                </div>
                <div className="info-box">
                    <h2
                        className="title"
                        style={{
                            color: titleColor,
                        }}
                    >
                        {title}
                    </h2>
                    <div className="email-info">
                        <div className="form-email-box">
                            <input
                                className="input-email"
                                type="text"
                                value={email}
                                placeholder={placeholder}
                                aria-label={placeholder}
                                onChange={handleEmail}
                            />
                            <button className="form-submit" type="button" onClick={submitSubscribe} aria-label={subscribe}>
                                {subscribe}
                            </button>
                        </div>
                        {inputMsg && (
                            <p className={`message ${tipType === 1 ? 'success' : tipType === 2 ? 'error' : ''}`}>
                                {tipType === 2 && (
                                    <svg className="tip-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <g clipPath="url(#clip0_324_7618)">
                                            <path
                                                d="M6.14966 3L6.46258 7.78185H7.53742L7.85034 3H6.14966ZM6.9932 8.94459C6.72109 8.94459 6.4898 9.0285 6.29932 9.22431C6.09524 9.40613 6 9.64389 6 9.9376C6 10.2173 6.09524 10.4551 6.29932 10.6509C6.4898 10.8467 6.72109 10.9446 6.9932 10.9446C7.26531 10.9446 7.5102 10.8467 7.71429 10.6649C7.90476 10.4691 8 10.2313 8 9.9376C8 9.64389 7.90476 9.40613 7.71429 9.22431C7.52381 9.0285 7.27891 8.94459 6.9932 8.94459Z"
                                                fill="#FA4500"
                                            />
                                            <path
                                                d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z"
                                                stroke="#FA4500"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_324_7618">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                )}
                                <span className="tip-text">{inputMsg}</span>
                            </p>
                        )}
                        <div className={`agreement ${inputMsg ? 'tip' : ''}`}>
                            <input type="checkbox" className="agree-check" id="agree" checked={checked} onChange={handleCheck} />
                            <label htmlFor="agree" className="agree-label">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: termsAndPolicy,
                                    }}
                                ></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="img-box pc">
                    <EditorImg pc={img} />
                </div>
            </div>
        </section>
    );
};

export default Content;
